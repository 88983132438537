@import "https://fonts.googleapis.com/css?family=Open+Sans+Condensed:700|Open+Sans:300,700";

// Kuljettajakouluts
$kt-color--blue: rgb(0, 160, 255);
$kt-color--blue-darken: rgb(47, 119, 184);
$kt-color--blue-lighten: rgb(0, 200, 255);

$kt-color--green: rgb(105, 190, 50);
$kt-color--green-darken: rgb(65, 150, 0);
$kt-color--green-lighten: rgb(125, 200, 60);

$kt-color--yellow: rgb(255, 200, 40);
$kt-color--yellow-darken: rgb(240, 160, 52);
$kt-color--yellow-lighten: rgb(255, 210, 30);

$kt-color--black: rgb(35, 31, 32);
$kt-color--white: rgb(250, 250, 250);

$kt-font-family--body: 'Open Sans', sans-serif;
$kt-font-family--heading: 'Open Sans Condensed', sans-serif;
$kt-font-weight--normal: 300;
$kt-font-weight--bold: 700;
$kt-font-weight--extra-bold: 900;

// Bootstrap
$enable-rounded: false;
$enable-flex: true;
$enable-transitions: true;
$brand-primary: $kt-color--blue;
$font-family-sans-serif: $kt-font-family--body;
$headings-font-family: $kt-font-family--heading;

$container-max-widths: (
    sm: 576px,
    md: 720px,
    lg: 846px,
    xl: 960px
) !default;

// Font Awesome
$fa-font-path: '/assets/fonts';

@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/font-awesome/scss/font-awesome";
@import "trix";

.navbar {
    padding: 0;
    font-size: .75em;
}

.navbar .navbar-brand {
    padding: 1.5em;
}

.navbar-nav .nav-link {
    padding: 3em 1.5em;
}

.navbar-nav .nav-link + .nav-link {
    margin: 0;
}

.navbar-nav .nav-link.login {
    background-color: $kt-color--blue;
    color: #fff;
}

.navbar-nav .nav-link.active:not(.home) {
    background-color: #373a3c;
    color: #fff;
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-content {
    flex: 1;
}

.breadcrumb {
    border-radius: 0;
    font-size: .75em;
    margin-bottom: 0;

    a {
        color: #fff;
    }
}

.jumbotron {
    border-radius: 0;

    @media (max-width: 960px) {
        .display-4 {
            font-size: 2rem;
        }
    }

    @media (max-width: 480px) {
        .display-4 {
            font-size: 1.5clrem;
        }
    }
}

.bg-hero {
    background-image: url("/images/hero-image.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.bg-primary a {
    color: #fff;
}

.card-block + .card-header,
.card-block + .card-block {
    border-top: 1px solid rgba(0,0,0,.125);
}

.card.no-border {
    border: 0;

    .card-title {
        //text-transform: uppercase;
    }

    .card-block {
        padding-left: 0;
        padding-right: 0;
    }
}

.kk-link {
    font-size: .75em;
    font-weight: bold;
    border-bottom: 20px solid $kt-color--blue;
    color: $kt-color--blue;
    display: block;
    padding-left: 30px;
    padding-bottom: 20px;
    position: relative;
    text-decoration: none;

    &::before {
        content: "";
        display: block;
        width: 20px;
        height: 3px;
        position: absolute;
        top: 7px;
        left: 0;
        background-color: $kt-color--blue;
    }

    &:hover,
    &:active,
    &:focus,
    &.active {
        border-color: $kt-color--blue-darken;
        color: $kt-color--blue-darken;
        text-decoration: none;

        &::before {
            content: "";
            display: block;
            width: 20px;
            height: 3px;
            position: absolute;
            top: 7px;
            left: 0;
            background-color: $kt-color--blue-darken;
        }
    }
}

.kk-tab-nav {
    display: flex;
}

.kk-tab-link {
    cursor: pointer;
    flex: 1;
    font-weight: 100;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    text-decoration: none !important;

    &.active {
        background-color: $kt-color--blue;
        color: $kt-color--white;
        text-decoration: none;
    }
}

.loader {
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    background: rgba($kt-color--blue-darken, .9);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .fa {
        font-size: 60px;
    }
}